@tailwind base;
@tailwind utilities;

@layer utilities {
	.font-times {
		font-family: "Times New Roman", ui-serif;
	}

	.font-marker {
		font-family: 'Permanent Marker', ui-sans-serif;
	}
}